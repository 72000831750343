import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Button from '../components/ui/Button';
import { bp } from '../styles/mediaBreakpoints';

const Wrapper = styled.main`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & > h1 {
    margin-bottom: 1.5em;
    display: flex;
    flex-direction: column;

    & > span {
      margin-bottom: 0.5em;
    }

    @media (min-width: 1600px) {
      flex-direction: row;
      align-items: center;

      & > span {
        &:not(:last-child) {
          margin-right: 1em;
        }
      }
    }

    @media (min-width: 1600px) {
      margin-bottom: 60px;
    }
  }

  .not-found-lost {
    font-size: 2em;
    margin-bottom: 2em;

    @media (min-width: 1600px) {
      margin-bottom: 60px;
    }
  }
  .not-found-nav {
    display: flex;
    flex-direction: column;

    & > span {
      margin-bottom: 0.5em;
    }

    @media (min-width: ${bp.lg}px) {
      flex-direction: row;
      align-items: center;

      & > span {
        &:not(:last-child) {
          margin-right: 1.5em;
        }
      }
    }
  }
`;
const ImgWrapper = styled(Img)`
  width: 50%;
  margin-top: 2em;
  margin-bottom: 2em;
  max-width: 1200px;

  ${({ theme }) => {
    return theme.mixins.useSkewed(
      css`
        clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
        padding: 1.5em 2em 1em;
        filter: drop-shadow(2px 2px 8px ${theme.colors.blue});
      `
    );
  }}
  ${({ theme }) => {
    return css`
      filter: drop-shadow(2px 2px 8px ${theme.colors.blue});
    `;
  }}

  @media (min-width: 1600px) {
    margin-bottom: 60px;
  }
`;

const NotFoundPage = ({ data, location }) => {
  return (
		<Layout location={location}>
			<SEO location={location} titlePrefix="404: Not Found" />
			<Wrapper>
				<ImgWrapper fluid={data.notFoundImage.childImageSharp.fluid} alt="" />
				<h1 className="not-found-lost">Lost?</h1>
				<nav className="not-found-nav">
					<span>
						<Button to="/">Go back home</Button>
					</span>
				</nav>
			</Wrapper>
		</Layout>
	);
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    notFoundImage: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "not_found_page/404_fog.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
